import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { account } from 'src/app/app.models';
import { AccountService } from 'src/app/_service/account.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import {GlobalConstantsComponent } from'../../common/global-constants/global-constants.component';
import{ CookieService} from"ngx-cookie-service";
@Component({
  selector: 'app-verifyaccount',
  templateUrl: './verifyaccount.component.html',
  styleUrls: ['./verifyaccount.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VerifyaccountComponent implements OnInit {
  public account: any[];
  public candidate_email:any;
  loading: boolean = true;
  router: any;
  constructor(private _CookieService:CookieService, private activatedRoute: ActivatedRoute,private _AccountService:AccountService,private http: HttpClient) { }

  ngOnInit(): void {
    let url_cr = window.location.href;
    const myArray = url_cr.split("/");
    this.candidate_email = myArray[4];
    this.getData();
  }
  getData(){
   
		let params = new HttpParams();
		params = params.set('encoded_email', this.candidate_email);
		this.http.get(GlobalConstantsComponent.apiURL+'/agentemailverification?' + params.toString())
		.subscribe((response: any) =>{
      if (response.status==0) {
        alert('Link expired.');
        window.location.href = GlobalConstantsComponent.siteUrl+'/404';
        //this.router.navigate(["/404"]);
      }else{
        this._CookieService.set('verification_cd', 'verified', 365, '/');

        
        window.location.href = GlobalConstantsComponent.siteUrl+'/login';
        //this.router.navigate(["/login"]);
      }
		})
	}
  }

function getData() {
  throw new Error('Function not implemented.');
}

