import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlobalConstantsComponent} from '../common/global-constants/global-constants.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationTop {
  private myNotificationSubject = new BehaviorSubject<string>('');
  public myallNotificationSubject$ = this.myNotificationSubject.asObservable();
  //public myData$ = this.myDataSubject.asObservable();
  //private firstNameSubject = new BehaviorSubject<string>('');
  //public firstnameData$ = this.firstNameSubject.asObservable();
  //public get_notif_url =  GlobalConstantsComponent.apiURL+"/getAllnotifications";
  
  constructor(private http:HttpClient) { }
  setAllNotifications(userid:string){
    this.http.get(GlobalConstantsComponent.apiURL+'/notificationsByUser/'+userid)
      .subscribe((response: any) =>{
        if(response.data.length>0){
            this.myNotificationSubject.next(response.data.length);
        }else{
            this.myNotificationSubject.next('0');
        }
      });
  }
 /* getAllNotifications() {
    return this.myNotificationSubject.asObservable();
  }*/
}