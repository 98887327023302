import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlobalConstantsComponent} from '../common/global-constants/global-constants.component';

@Injectable({
  providedIn: 'root'
})
export class TestimonialService {
  static gettestimonialData() {
    throw new Error('Method not implemented.');
  } 
  public getalltestimonials = GlobalConstantsComponent.apiURL+"/testimonial";
  public save_testimonials =  GlobalConstantsComponent.apiURL+"/testimonial";
  public update_testimonials = GlobalConstantsComponent.apiURL+"/testimonial";
  public delete_testimonials = GlobalConstantsComponent.apiURL+"/testimonial";
  
  constructor(private http:HttpClient) { }
  gettestimonialData(){
    return this.http.get<any[]>(this.getalltestimonials);
	}
    save_testimonial(testimonial_img:any,name:string, designation:string,description:string, status:any){
    return this.http.post<{data:any}>(this.save_testimonials,{testimonial_img:testimonial_img,name:name, designation:designation,description:description, status:status});
  }
  update_testimonial(id:string, name:string, designation:string,description:string, status:any){
    return this.http.patch<{updateddata:any}>(this.update_testimonials+'/'+id,{name:name, designation:designation,description:description, status:status});
  }
  delete_testimonial(id:string){
    return this.http.delete<{data:any}>(this.delete_testimonials+'/'+id);
  }
  public getTestimonials(){

  }
}
