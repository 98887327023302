import { Menu } from './menu.model';

export const horizontalMenuItems = [ 
    new Menu (1, 'NAV.HOME', '/', null, null, false, 0),
    new Menu (2, 'NAV.PROPERTIES', '/properties', null, null, false, 0), 
    new Menu (3, 'NAV.AGENTS', '/agents', null, null, false, 0), 
    new Menu (60, 'NAV.CONTACT', '/contact', null, null, false, 0),  
    new Menu (70, 'NAV.ABOUT_US', '/about', null, null, false, 0),
    new Menu (42, 'BLOG', '/blog', null, null, false, 0),
    new Menu (44, 'REGISTER', '/register', null, null, false, 0),
    new Menu (43, 'LOGIN', '/login', null, null, false, 0),
]

export const verticalMenuItems = [ 
    new Menu (1, 'NAV.HOME', '/', null, null, false, 0), 
    new Menu (2, 'NAV.PROPERTIES', '/properties', null, null, false, 0), 
    new Menu (3, 'NAV.AGENTS', '/agents', null, null, false, 0),
    new Menu (60, 'NAV.CONTACT', '/contact', null, null, false, 0),  
    new Menu (70, 'NAV.ABOUT_US', '/about', null, null, false, 0), 
    new Menu (42, 'BLOG', '/blog', null, null, false, 0),
    new Menu (44, 'REGISTER', '/register', null, null, false, 0),
    new Menu (43, 'LOGIN', '/login', null, null, false, 0),      
  
]