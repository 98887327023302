// mobile-number.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ourmobileNumberMask'
})
export class OurMobileNumberPipe implements PipeTransform {
  transform(value: string): string {
    const position = 3; // Specify the position after which characters should be replaced
    const replacementChar = '*'; // Specify the replacement character

    const mobilePrefix = value.substring(0, position);
    const mobileSuffix = value.substring(position).replace(/./g, replacementChar);

    return mobilePrefix + mobileSuffix;
  }
}
