import { Injectable } from '@angular/core';  
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';  
@Injectable({ providedIn: 'root' })  
export class AuthGuardAdmin  {  
    constructor(private _router: Router) { }  
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {  
        if (localStorage.getItem('currentAdminLgn')) {  
            return true;  
        }  
        this._router.navigate(['/adminlogin']);  
        return false;  
    }  
    login(admin_id: string, admin_name: string, admin_password: string) { 
        localStorage.setItem('currentAdminLgn', "admin");  
        localStorage.setItem('login_admin_id', admin_id);
        localStorage.setItem('login_admin_name', admin_name);
        localStorage.setItem('login_admin_password', admin_password);
        
        return true;  
  }  
  logout() {  
        localStorage.removeItem('currentAdminLgn');  
        localStorage.removeItem('login_admin_id');
        localStorage.removeItem('login_admin_name');
        localStorage.removeItem('login_admin_password');
  }  
  public get loggedIn(): boolean {  
    return (localStorage.getItem('currentAdminLgn') !== null) ; 
    
  }  

} 