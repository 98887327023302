import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlobalConstantsComponent} from '../common/global-constants/global-constants.component';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService { 
  public getallfeedback = GlobalConstantsComponent.apiURL+"/feedback";
  public save_feedback =  GlobalConstantsComponent.apiURL+"/feedback";
  public update_feedback = GlobalConstantsComponent.apiURL+"/feedback";
  public delete_feedback = GlobalConstantsComponent.apiURL+"/feedback";

  
  
  constructor(private http:HttpClient) { }
  getfeedbackData(){
		return this.http.get<any[]>(this.getallfeedback);
	}
  savefeedback(email:string,message:string){
    return this.http.post<{data:any}>(this.save_feedback,{email:email, message:message});
  }
//   updatefeedback(id:string,email:string, description:string, status:any){
//     return this.http.patch<{updateddata:any}>(this.update_feedback+'/'+id,{name:name, email:email, address:address, description:description, status:status});
//   }
  deletefeedback(id:string){
    return this.http.delete<{data:any}>(this.delete_feedback+'/'+id);
  }
  restoreFeedback(id:string, trash:any){
    return this.http.patch<{updateddata:any}>(this.update_feedback+'/'+id,{trash:trash});
  }
  trashFeedback(id:string, trash:any){
    return this.http.patch<{updateddata:any}>(this.update_feedback+'/'+id,{trash:trash});
  }
}