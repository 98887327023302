import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { emailValidator } from '../../utils/app-validators';
import { FeedbackService } from 'src/app/_service/feedback.service';
import { SubscribeService } from'src/app/_service/subscribe.service';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  styles: [`
    ::ng-deep .mat-snack-bar-container{
      color: #155724 !important;
      background-color: #d4edda !important;
      border-color: #c3e6cb !important;
    }
    ::ng-deep .mat-simple-snackbar-action {
      color: red;
    }
    button.mat-focus-indicator.mat-button.mat-button-base {
      color: red;
  }
    `]
})
export class FooterComponent implements OnInit {
  public lat: number = 40.678178;
  public lng: number = -73.944158;
  public zoom: number = 12; 
  public mapStyles:any = [
    {
        "featureType": "all",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "saturation": 36
            },
            {
                "color": "#000000"
            },
            {
                "lightness": 40
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#000000"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 17
            },
            {
                "weight": 1.2
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#8b9198"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#323336"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#414954"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#2e2f31"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#7a7c80"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#242427"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#202022"
            },
            {
                "lightness": 29
            },
            {
                "weight": 0.2
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 18
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#393a3f"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#202022"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#393a3f"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#202022"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 19
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#202124"
            }
        ]
    }
  ];
  public feedbackForm: UntypedFormGroup;
  public subscribeForm: UntypedFormGroup;
    enqmsg: string;
    enqmessage: boolean;
  constructor(private _snackBar: MatSnackBar, public formBuilder: UntypedFormBuilder, public _subscribeService:SubscribeService, public FeedbackService : FeedbackService) { }

  ngOnInit() {
    this.feedbackForm = this.formBuilder.group({ 
      email: ['', Validators.compose([Validators.required, emailValidator])], 
      message: ['', Validators.required]
    });
    this.subscribeForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, emailValidator])]
    })
  }
 
  public onFeedbackFormSubmit(values:Object):void {
   
    if (this.feedbackForm.valid) {
    //   console.log(values);
    const self = this;
   
     this.FeedbackService.savefeedback(this.feedbackForm.value.email, this.feedbackForm.value.message).subscribe(async res => {
       
            self.feedbackForm.reset();
            for (let control in self.feedbackForm.controls) {
            self.feedbackForm.controls[control].setErrors(null);
            }
      this.enqmsg = "We are immensely grateful for your valuable feedback, which will help us improve and serve you better - thank you for sharing your thoughts with us!";
        this.enqmessage=true;
        setTimeout(function() {
          this.enqmessage = false;
      }.bind(this), 5000 );
   
      }); 
      this.feedbackForm.reset();
    }
    
  }

  public onSubscribeFormSubmit(values:Object):void {
    if (this.subscribeForm.valid) {
        const self = this;
   
        this._subscribeService.savesubscription(this.subscribeForm.value.email).subscribe(async res => {
            
            self.subscribeForm.reset();
            for (let control in self.subscribeForm.controls) {
                self.subscribeForm.controls[control].setErrors(null);
            }
            this._snackBar.open('Your support means the world to us - thank you for subscribing to our newsletter and joining our community!', 'x', {
                duration: 5000,
                panelClass: 'notif-success'
              });
        });   
        this.subscribeForm.reset();
    }
  }

}
