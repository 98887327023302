import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Subscription } from 'rxjs'; 
import { ActivatedRoute } from '@angular/router';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators'; 
import { Settings, AppSettings } from '../../app.settings';
import { AppService } from '../../app.service';
import { FavoritesService } from '../../_service/favorites.service';
import { PropertiesService } from '../../_service/properties/properties.service'
import { Property, Pagination } from '../../app.models'; 
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import { isPlatformBrowser } from '@angular/common';
import { CompareOverviewComponent } from '../../shared/compare-overview/compare-overview.component';
import {GlobalConstantsComponent} from '../../common/global-constants/global-constants.component'
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-properties-carousel',
  templateUrl: './properties-carousel.component.html',
  styleUrls: ['./properties-carousel.component.scss']
})
export class PropertiesCarouselComponent implements OnInit {
  @ViewChild('sidenav') sidenav: any;
  public sidenavOpen:boolean = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public psConfig: PerfectScrollbarConfigInterface = {
    wheelPropagation:true
  };
  public config: SwiperConfigInterface = {}; 
  public properties: Property[];
  public gallery_images:any[];
  public viewType: string = 'grid';
  public viewCol: number = 33.3;
  public count: number = 12;
  public sort: string;
  private sub: any;
  public pid: any;
  public localStorage:any;
  public myproperty:any;
  public total:0;
  public searchFields: any;
  public removedSearchField: string;
  public pagination:Pagination = new Pagination(1, this.count, null, 2, 0, 0); 
  public message:string;
  public watcher: Subscription;
  public upload_fold_path: string;

  public settings: Settings
  totalProperty: any;
  constructor(public appSettings:AppSettings, 
              public appService:AppService, 
              public _FavoritesService:FavoritesService,
              private activatedRoute: ActivatedRoute,
              private _snackBar: MatSnackBar,
              public propertiesService:PropertiesService,
              public mediaObserver: MediaObserver,
              @Inject(PLATFORM_ID) private platformId: Object) {
              this.settings = this.appSettings.settings;    
              this.watcher = mediaObserver.asObservable()
              .pipe(filter((changes: MediaChange[]) => changes.length > 0), map((changes: MediaChange[]) => changes[0]))
              .subscribe((change: MediaChange) => {
              if (change.mqAlias == 'xs') {
              this.sidenavOpen = false;
              this.viewCol = 100;
              }
              else if(change.mqAlias == 'sm'){
              this.sidenavOpen = false;
              this.viewCol = 50;
              }
              else if(change.mqAlias == 'md'){
              this.viewCol = 50;
              this.sidenavOpen = true;
              }
              else{
              this.viewCol = 33.3;
              this.sidenavOpen = true;

              this.localStorage= localStorage.getItem("login_user_id");
            
              }
              });
              this.upload_fold_path =GlobalConstantsComponent.siteUrl+'/assets/serverimg/';
    }
            
    ngOnInit() {

    this.getProperties();
    }
    ngOnDestroy(){ 
    this.watcher.unsubscribe();
    }
  public getStatusBgColor(status){
    switch (status) {
      case 'For Sale':
        return '#558B2F';  
      case 'For Rent':
        return '#1E88E5'; 
      case 'Open House':
        return '#009688';
      case 'No Fees':
        return '#FFA000';
      case 'Hot Offer':
        return '#F44336';
      case 'Sold':
        return '#000';
      default: 
        return '#01579B';
    }
  }
  public getProperties(){   
      this.propertiesService.getfeatureProperty().subscribe(data => { 
      this.properties = data['properties'];
      this.totalProperty=data['total'];
      let result = this.filterData(data['properties']); 
      if(result.data['properties'].length == 0){
          this.properties.length = 0;
          this.pagination = new Pagination(1, this.count, null, 2, 0, 0);  
          this.message = 'No Results Found';
          return false;
      } 
      this.pagination = result.pagination;
      this.message = null;
    })
  }
  public resetPagination(){ 
    if(this.paginator){
      this.paginator.pageIndex = 0;
    }
    this.pagination = new Pagination(1, this.count, null, null, this.pagination.total, this.pagination.totalPages);
  }

  public filterData(data){
    return this.appService.filterData(data, this.searchFields, this.sort, this.pagination.page, this.pagination.perPage);
  }
  public searchClicked(){ 
    this.properties.length = 0;
    this.getProperties(); 
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0,0);
    }  
  }
  public searchChanged(event){
    event.valueChanges.subscribe(() => {   
      this.resetPagination(); 
      this.searchFields = event.value;
      setTimeout(() => {      
        this.removedSearchField = null;
      });
      if(!this.settings.searchOnBtnClick){     
        this.properties.length = 0;  
      }            
    }); 
    event.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => { 
      if(!this.settings.searchOnBtnClick){     
        this.getProperties(); 
      }
    });       
  } 
  public removeSearchField(field){ 
    this.message = null;   
    this.removedSearchField = field; 
  } 
  public changeCount(count){
    this.count = count;   
    this.properties.length = 0;
    this.resetPagination();
    this.getProperties();
  }
  public changeSorting(sort){    
    this.sort = sort; 
    this.properties.length = 0;
    this.getProperties();
  }
  public changeViewType(obj){ 
    this.viewType = obj.viewType;
    this.viewCol = obj.viewCol; 
  } 
  public onPageChange(e){ 
    this.pagination.page = e.pageIndex + 1;
    this.getProperties();
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0,0);
    } 
  }

  public addToCompare(property:any){
    this.appService.addToCompare(property, CompareOverviewComponent, (this.settings.rtl) ? 'rtl':'ltr'); 
  }
  public onCompare(property:any){
    return this.appService.Data.compareList.filter(item=>item.id == property._id)[0];
  }
  // public getPropertyById(id){
  //   this.propertiesService.getPropertyById(id).subscribe(data=>{
  //     this.myproperty = data['data'][0];   
      
      
  //   });
  // }

  public addToFavorites(propertyId:any){
    this.propertiesService.getPropertyById(propertyId).subscribe(data=>{
      this.myproperty = data['data'][0];  
      this.afterClick(this.myproperty);
    });
  }

  public afterClick(data:any){
  var user_id= this.localStorage;
  var property_id= data._id;
  var property_title= data.title;
  var property_slug= data.slug;
   this._FavoritesService.savefavorites(user_id,property_id,property_title, property_slug).subscribe(res => {
     this._snackBar.open('Property Favorite saved successfully', 'x', {
       duration: 3000,
       panelClass: 'notif-success'
     });
     // this.dialogRef.close();
   });
}
  public onFavorites(property:any){
    return this.appService.Data.favorites.filter(item=>item.id == property._id)[0];
  }
  gallery_config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    allowTouchMove: true,
    pagination:{el:'.swiper-pagination',clickable:true},
    autoplay: {
      delay: 3000,
      disableOnInteraction: true
    }
};

  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView: 4,
      spaceBetween: 16,       
      keyboard: true,
      navigation: { nextEl: '.prop-next', prevEl: '.prop-prev'},
      pagination: true,
      grabCursor: true,        
      loop: false,
      preloadImages: true,
      lazy: false,    
      breakpoints: { 
        320: {
          slidesPerView: 1
        },
        600: {
          slidesPerView: 2
        },
        960: {
          slidesPerView: 3
        },
        1280: {
          slidesPerView: 4
        }
      }
    }
  }

}