import { Injectable } from '@angular/core';
import { Socket } from'ngx-socket-io';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(private socket: Socket) {}

  sendMessage(sender: string, receiver: string, message: string, room: string) {
    this.socket.emit('message', { sender, receiver, message, room });
  }

  receiveMessages() {
    return this.socket.fromEvent('new-message');
  }
}
