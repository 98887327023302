import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Propertytype } from 'src/app/app.models';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AppSettings, Settings } from 'src/app/app.settings';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort } from "@angular/material/sort";
import { SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { HttpClient, HttpParams } from '@angular/common/http';
import {debounceTime, distinctUntilChanged, startWith, tap, delay} from 'rxjs/operators';
import {merge, fromEvent} from "rxjs";
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { SlicePipe } from '@angular/common';
import { TestimonialService } from 'src/app/_service/testimonial.service';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import { GlobalConstantsComponent } from '../../common/global-constants/global-constants.component';


@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {
  public config: SwiperConfigInterface = { };
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };

    public testimonial: any[];
	public imgPath:any;
	loading: boolean = true;
	dataSource = new MatTableDataSource<any>();
	title = '';
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;
	@ViewChild('input') input: ElementRef;
	@HostListener('matSortChange', ['$event'])
	public page: any;
	public count = 11;
	public settings:Settings;
	public current_lmt = '10';
	public current_ofct = '0';
	public activesort='_id';
	public activedir='desc';
	public search_dt = '';
  constructor(private changeDetectorRefs: ChangeDetectorRef, private _snackBar: MatSnackBar, private _TestimonialService:TestimonialService, public dialog: MatDialog, public appSettings:AppSettings, private http: HttpClient) {
    this.settings = this.appSettings.settings;
   }

  ngOnInit(): void {
    this.getData(this.current_ofct, this.current_lmt);
  }
  getData(offset, limit){
		let params = new HttpParams();
		params = params.set('offset', offset);
		params = params.set('limit', limit);
		this.current_ofct =  offset;
		this.current_lmt =  limit;
		params = params.set('sort_col', this.activesort);
		params = params.set('sort_order', this.activedir);
		params = params.set('searchdata', this.search_dt);
		this.http.get(GlobalConstantsComponent.apiURL+'/testimonial?' + params.toString())
		.subscribe((response: any) =>{
			this.loading = false;
			this.testimonial = response.testimonials;
			this.testimonial.length = response.total;
			this.dataSource = new MatTableDataSource<any>(this.testimonial);
			this.dataSource.paginator = this.paginator;
			this.imgPath=GlobalConstantsComponent.siteUrl+'/assets/serverimg/';
		})
	}
  public doFilter = (value: string) => {
		let params = new HttpParams();
		this.search_dt = value.trim();
		this.current_ofct='0'
		this.current_lmt='10'
		params = params.set('offset', this.current_ofct);
		params = params.set('limit', this.current_lmt);
		params = params.set('sort_col', this.activesort);
		params = params.set('sort_order', this.activedir);
		params = params.set('searchdata', this.search_dt);
		if(this.search_dt!=''){
			this.http.get(GlobalConstantsComponent.apiURL+'/gettestimonialsearch?' + params.toString())
			.subscribe((response: any) =>{
				this.loading = false;
				this.testimonial.length = response.total;
				this.dataSource = new MatTableDataSource<any>(response.data);
				this.changeDetectorRefs.detectChanges();
				this.dataSource._updateChangeSubscription();
				this.dataSource.paginator = this.paginator;
				this.paginator.pageIndex = 0;
			});
		}else{
			this.getData(this.current_ofct, this.current_lmt);
		}
	}
 
	
	public clickhandle(redir_link){
		window.open(redir_link, "_blank");
	}
  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView: 1,
      spaceBetween: 0,       
      keyboard: true,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true,  
      // breakpoints: {
      //   480: {
      //     slidesPerView: 1
      //   },
      //   740: {
      //     slidesPerView: 2,
      //   },
      //   960: {
      //     slidesPerView: 3,
      //   }
      // }
    }
  }

}


