import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlobalConstantsComponent} from '../common/global-constants/global-constants.component';

@Injectable({
  providedIn: 'root'
})
export class FavoritesService { 
  public getallfavorites = GlobalConstantsComponent.apiURL+"/favorites";
  public save_favorites =  GlobalConstantsComponent.apiURL+"/favorites";
  public get_favorites_by =  GlobalConstantsComponent.apiURL+"/getfavouritesBypropAndUser";
  public update_favorites = GlobalConstantsComponent.apiURL+"/favorites";
  public delete_favorites = GlobalConstantsComponent.apiURL+"/favorites";
  
  constructor(private http:HttpClient) { }
  getfavoritesData(){
		return this.http.get<any[]>(this.getallfavorites);
	}
  getFavouriteByUserIdAndPropertyId(user_id:any, property_id:any){
    return this.http.post<{data:any}>(this.get_favorites_by,{user_id:user_id, property_id:property_id});

  }
  savefavorites(user_id:string, property_id:string,property_title:string,property_slug:string){
    return this.http.post<{data:any}>(this.save_favorites,{user_id:user_id, property_id:property_id,property_title:property_title,property_slug:property_slug});
  }
  updatefavorites(id:string,status:string){
    return this.http.patch<{updateddata:any}>(this.update_favorites+'/'+id,{status:status});
  }
  deletefavorites(id:string){
    return this.http.delete<{data:any}>(this.delete_favorites+'/'+id);
  }
}