import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-global-constants',
  templateUrl: './global-constants.component.html',
  styleUrls: ['./global-constants.component.scss']
})
export class GlobalConstantsComponent {

        // public static apiURL: string = "http://localhost:3000";
        // public static siteUrl: string = "http://localhost:4200";
        public static apiURL: string = "https://nineestateserver-2.onrender.com";
        public static siteUrl: string = "https://9estate.com";
}
