import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import {uploadAdservice} from '../_service/advertisement.service'
import { GlobalConstantsComponent } from '../common/global-constants/global-constants.component';
import { HttpClient, HttpParams } from '@angular/common/http';


@Component({
  selector: 'app-coommon-meta',
  template: ''
})
export class CoommonMetaComponent implements OnInit {
  blogurl: string;

  constructor(private metaService: Meta, private router: Router, public _uploadAdservice:uploadAdservice,private http: HttpClient, public titleService: Title,) { }

  ngOnInit() {
 
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     console.log("what is this: ",event.url);
    //     this.updateMetaTags();
    //   }
    // });
    this.blogurl = window.location.href;
    this.getPageData()
  }

  
  getPageData() {
    this.http.get(GlobalConstantsComponent.apiURL + '/pagemeta')
      .subscribe((response: any) => {
        const pageMetadatas = response.pagemetadatas;
        for (const pageMeta of pageMetadatas) {
          if (pageMeta.pageurl === this.blogurl) {
            this.titleService.setTitle(pageMeta.pagemeta);
            this.metaService.updateTag({ name: 'description', content:  pageMeta.pagedescription});
            this.metaService.updateTag({ name: 'keywords', content:  pageMeta.pagekeyword});

            // Add more properties as needed
          }
        }
      });
  }
  
  
}

