/// <reference types="@types/googlemaps" />
import { Component, OnInit, Inject, Input, Output, EventEmitter, ViewChild, ElementRef, NgZone } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray  } from '@angular/forms';
import { AppService } from '../../app.service'; 
import { PropertiesService } from 'src/app/_service/properties/properties.service';
import { Location } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { MapsAPILoader } from '@agm/core';
import { ActivatedRoute } from '@angular/router';
import { Router,NavigationEnd  } from '@angular/router';
import { GlobalConstantsComponent } from '../../common/global-constants/global-constants.component';
declare var jQuery:any;
@Component({
  selector: 'app-properties-search',
  templateUrl: './properties-search.component.html',
  styleUrls: ['./properties-search.component.scss']
})
export class PropertiesSearchComponent implements OnInit {
  @ViewChild('addressAutocomplete') addressAutocomplete: ElementRef;
  @Input() variant:number = 1;
  @Input() vertical:boolean = false;
  @Input() searchOnBtnClick:boolean = false;
  @Input() removedSearchField:string;
  @Output() onSearchChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSearchClick: EventEmitter<any> = new EventEmitter<any>();
  public showMore: boolean = false;
  public form: UntypedFormGroup;
  public propertyTypes = [];
  public propertyStatuses = [];
  public features = [];
  public cur_city:any;
  public siteUrl:any;
  public cur_prop_type:any;
  public cur_property_status:any;
  public cities = [];
  public neighborhoods = [];
  public streets = [];
  public features_ar = [];
  public active_features = [];
  public lat: number = 29.49225722008518;
  public lng: number = 78.37646484375;
  public zoom: number = 12;
  public search_loc:any;
  public search_lat:any;
  public cstm_ft:any;
  public search_long:any;
  newArray : any = [];
  public pstatusP:any = [];
  public cur_uri:any;
  constructor(@Inject(DOCUMENT) private document: Document, private route: ActivatedRoute,private location: Location,
  private ngZone: NgZone, private router: Router, private mapsAPILoader: MapsAPILoader, public appService:AppService, public fb: UntypedFormBuilder, public __PropertiesService:PropertiesService) { 
    this.siteUrl = this.document.baseURI;
    this.cur_property_status = '';
    this.cur_prop_type='';
    this.search_loc = '';

    
  }
  ngOnInit() {
    
    this.cur_uri = this.router.url;
   
    if(this.vertical){
      this.showMore = true;
    };
    this.cur_city='';
    this.__PropertiesService.getPropertytypes().subscribe(data => {
      this.propertyTypes = data; 
     
    });
    this.__PropertiesService.getPropertystatuses().subscribe(data => {
      this.propertyStatuses = data; 
      this.route.params.subscribe(params => {
        let pro_type_filt = '';
        let pro_stts_filt = '';
        if(params.location){
          if(params.location.trim()=='for-rent' || params.location.trim()=='for-sale'){
            pro_stts_filt = params.location;
          }else{
            pro_type_filt = params.location;
          }
        }
        if(params.type){
          pro_stts_filt = params.type;
          let modifiedString = pro_stts_filt.replace(/-/g, ' ');
          let capitalizedString = this.capitalizeWords(modifiedString);
          this.pstatusP.push(capitalizedString);
        }
        if(params.type2){
          
          
          pro_stts_filt = params.type2;
          let modifiedString = pro_stts_filt.replace(/-/g, ' ');
          let capitalizedString = this.capitalizeWords(modifiedString);
          this.pstatusP.push(capitalizedString);
        }
        
        if(pro_type_filt!=''){
          var protp = pro_type_filt.replace(/-/g, ' ').toLowerCase();
          this.form.controls.propertyType.setValue(protp);
        }
      });
    }); 
    this.__PropertiesService.getActivePropertyFeatures().subscribe(data => {
       this.active_features = data;
    }); 
    this.cities = this.appService.getCities();
    this.neighborhoods = this.appService.getNeighborhoods();
    this.streets = this.appService.getStreets();
    this.features = this.appService.getFeatures();
    this.form = this.fb.group({
      propertyType: null,
      propertyStatus: null, 
      price: this.fb.group({
        from: null,
        to: null 
      }),
      location: null,
      search_loc:null,
      lat:null,
      long:null,
      neighborhood: null,
      street: null,
      bedrooms: this.fb.group({
        from: null,
        to: null 
      }),
      bathrooms: this.fb.group({
        from: null,
        to: null 
      }),
      garages: this.fb.group({
        from: null,
        to: null 
      }),
      area: this.fb.group({
        from: null,
        to: null 
      }),
      yearBuilt: this.fb.group({
        from: null,
        to: null 
      }),       
      features: this.buildFeatures(),       
      custom_features:null
    }); 
    //this.onSearchChange.emit(this.form);
  
    this.placesAutocomplete();
  }
  capitalizeWords(str: string): string {
    return str.replace(/\b\w/g, match => match.toUpperCase());
  }
  public onSearch(search_k:any){
      if(search_k!=''){
        jQuery('html body .pac-container').css({'opacity':'1'});
      }else{
        jQuery('html body .pac-container').css({'opacity':'0'});
      }
  }
  public onSearchChangeFun(search_k:any){
    jQuery('html body .pac-container').css({'opacity':'0'});
  }
  private placesAutocomplete() {
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.addressAutocomplete.nativeElement);
      autocomplete.addListener("place_changed", () => {
        jQuery('html body .pac-container').css({'opacity':'0'});
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          };
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
       
          this.form.controls.search_loc.setValue(place.formatted_address);
          this.form.controls.location.setValue(place.formatted_address);
          this.getAddress();
        });
      });
    });
  }
  public getAddress() {
    this.appService.getAddress(this.lat, this.lng).subscribe(response => {
      if (response['results'].length) {
        //console.log('address homapage ->', response['results'][0]);
        let address = response['results'][0].formatted_address;
        this.form.controls.lat.setValue(response['results'][0].geometry.location.lat);
        this.form.controls.long.setValue(response['results'][0].geometry.location.lng);
        //this.form.controls.search_loc.setValue(address);
        //this.form.controls.location.setValue(address);
        
      }
    })
  }
  public buildFeatures() {
    const arr = this.features.map(feature => { 
      return this.fb.group({
        id: feature.id,
        name: feature.name,
        selected: feature.selected
      });
    })   
    return this.fb.array(arr);
  }
  ngOnChanges(){ 
    if(this.removedSearchField){ 
      if(this.removedSearchField.indexOf(".") > -1){
        let arr = this.removedSearchField.split(".");
        this.form.controls[arr[0]]['controls'][arr[1]].reset();
      } 
      else if(this.removedSearchField.indexOf(",") > -1){        
        let arr = this.removedSearchField.split(","); 
        this.form.controls[arr[0]]['controls'][arr[1]]['controls']['selected'].setValue(false);  
      }
      else{
        this.form.controls[this.removedSearchField].reset();
      }  
    }  

  }
  public reset(){     
    this.form.reset({ 
      propertyType: null,
      propertyStatus: null, 
      price: {
        from: null,
        to: null 
      },
      location: null,
      search_loc:null,
      city: null,
      zipCode: null,
      neighborhood: null,
      street: null,
      lat:null,
      long:null,
      bedrooms: {
        from: null,
        to: null 
      },
      bathrooms: {
        from: null,
        to: null 
      },
      garages: {
        from: null,
        to: null 
      },
      area: {
        from: null,
        to: null 
      },
      yearBuilt: {
        from: null,
        to: null 
      },       
      features: '',       
      custom_features: ''    
    }); 
    if(this.cur_uri=='/properties'){
      this.location.replaceState('/properties');
      this.search();
    }else{
      this.search();
      this.location.replaceState('');
    }
   
  }
  public search(){ 
    this.onSearchChange.emit(this.form); 
    //this.onSearchClick.emit(); 
  }
  public onSelectCity(cityval){
    this.cur_city = cityval.name;
    const currentUrl = '';
    var textToAppend = '';
    if(this.cur_city!=''){
      if(this.cur_prop_type!=''){
        if(this.cur_property_status!=''){
          textToAppend = 'singapore/'+this.cur_city.toLowerCase().replace(/ /g, "-")+'/'+this.cur_prop_type.toLowerCase().replace(/ /g, "-")+this.cur_property_status.toLowerCase().replace(/ /g, "-");         
        }else{
          textToAppend = 'singapore/'+this.cur_city.toLowerCase().replace(/ /g, "-")+'/'+this.cur_prop_type.toLowerCase().replace(/ /g, "-");
        }
      }else if(this.cur_property_status!=''){
        textToAppend = 'singapore/'+this.cur_city.toLowerCase().replace(/ /g, "-")+this.cur_property_status.toLowerCase().replace(/ /g, "-");
      }else{
        textToAppend = 'singapore/'+this.cur_city.toLowerCase().replace(/ /g, "-");
      }
      const newUrl = `${currentUrl}/${textToAppend}`;
      this.location.replaceState(newUrl);
    }
    //this.onSearchChange.emit(this.form);
    this.form.controls['neighborhood'].setValue(null, {emitEvent: false});
    this.form.controls['street'].setValue(null, {emitEvent: false});
  }
  public onPropertyType(propertyTypeval){ 
    var myArray_slug = this.cur_uri.split("/");
    var count_ur = 0;
    if(Array.isArray(myArray_slug) && myArray_slug.length>1){
      if(myArray_slug[1]==''){
        count_ur = 1;
      }
    }
    if(count_ur>0){
      this.cur_prop_type = propertyTypeval;
      const currentUrl = '';
      if(this.cur_city!=''){
        const textToAppend = 'singapore/'+this.cur_city.toLowerCase().replace(/ /g, "-")+'/'+this.cur_prop_type.toLowerCase().replace(/ /g, "-");
        const newUrl = `${currentUrl}/${textToAppend}`;
        this.location.replaceState(newUrl);
      }else if(this.cur_prop_type!=''){
        const textToAppend = 'singapore/'+this.cur_prop_type.toLowerCase().replace(/ /g, "-");
        const newUrl = `${currentUrl}/${textToAppend}`;
        this.location.replaceState(newUrl);
      }
    }
    //this.onSearchChange.emit(this.form);
  }
  public onPropertyStatus(propertyStatusVal){
    
    var myArray_slug = this.cur_uri.split("/");
    var count_ur = 0;
    if(Array.isArray(myArray_slug) && myArray_slug.length>1){
      if(myArray_slug[1]==''){
        count_ur = 1;
      }
    }
    if(count_ur>0){

      var textToAppend = '';
      const currentUrl = '';
      if(this.cur_city!=''){
        if(this.cur_prop_type!=''){
          textToAppend = 'singapore/'+this.cur_city.toLowerCase().replace(/ /g, "-")+'/'+this.cur_prop_type.toLowerCase().replace(/ /g, "-");
        }else{
          textToAppend = 'singapore/'+this.cur_city.toLowerCase().replace(/ /g, "-");
        }
      }else if(this.cur_prop_type!=''){
        textToAppend = 'singapore/'+this.cur_prop_type.toLowerCase().replace(/ /g, "-");
      }else{
        textToAppend = 'singapore';
      }
      this.cur_property_status = '';
      if (Array.isArray(propertyStatusVal) && propertyStatusVal.length>0) {
        for(let i=0; i<propertyStatusVal.length; i++) {
          if(propertyStatusVal[i]!=''){
            this.cur_property_status+='/'+propertyStatusVal[i].toLowerCase().replace(/ /g, "-");
            textToAppend = textToAppend+'/'+propertyStatusVal[i].toLowerCase().replace(/ /g, "-");
          }
          if(i==propertyStatusVal.length-1){
              const newUrl = `${currentUrl}/${textToAppend}`;
              this.location.replaceState(newUrl);
          }
        }
      }else{
        if(textToAppend!=''){
          const newUrl = `${currentUrl}/${textToAppend}`;
          this.location.replaceState(newUrl);
        }
      }
    }
    //this.onSearchChange.emit(this.form);
  }
  public onSelectNeighborhood(){
    this.form.controls['street'].setValue(null, {emitEvent: false});
  }
  public getAppearance(){
    return (this.variant != 3) ? 'outline' : '';
  }
  public getFloatLabel(){
    return (this.variant == 1) ? 'always' : '';
  }
  onbedroom(section:any){
   if(section=='from'){
      if(this.form.value.bedrooms.to>0){
        if(this.form.value.bedrooms.to<this.form.value.bedrooms.from){
          this.form.reset({
            bedrooms: {
                   from: null
                 }, 
           });
          alert('Please select Bedroom From less than Bedroom To.');
        }
      }
   }else if(section=='to'){
    if(this.form.value.bedrooms.from>0){
      if(this.form.value.bedrooms.to<this.form.value.bedrooms.from){
        this.form.reset({
          bedrooms: {
                 to: null
               }, 
         });
        alert('Please select Bedroom To greater than Bedroom From.');
      }
    }
   }
   
   
    //this.onSearchChange.emit(this.form);
  }
  changeEvent($event, data) {
    //all features
    let obj = data;
    if($event.checked){
      // Pushing the object into array
      this.newArray.push(obj);
    }else {
      let el = this.newArray.find(itm => itm===data);
      if(el)
      this.newArray.splice(this.newArray.indexOf(el),1);
    }
    let features_all = this.newArray.join(',');
    if(features_all!=''){
      this.form.controls['custom_features'].setValue(features_all);
      
        //this.onSearchChange.emit(this.form);
      
    }
    
  }
  
}
