export class Property {
  constructor(public id: number,
    public cityId:string,
    public neighborhood_id:string,
    public street_id: string,
    public title: string,
    public desc: string,
    public propertyType: string,
    public propertyStatus: string[],
    public city: string,
    public uploded_by_name: string,
    public zipCode: string[],
    public neighborhood: string[],
    public street: string[],
    public location: Location,
    public formattedAddress: string,
    public features: string[],
    public videoname: string,
    public featured: boolean,
    public priceDollar: Price,
    public priceEuro: Price,
    public bedrooms: number,
    public bathrooms: number,
    public garages: number,
    public area: Area,
    public yearBuilt: number,
    public ratingsCount: number,
    public ratingsValue: number,
    public additionalFeatures: AdditionalFeature[],
    public gallery: Gallery[],
    public plans: Plan[],
    public videos: Video[],
    public published: string,
    public lastUpdate: string,
    public views: number) { }
}
export class Category {
  constructor(public _id: number,
    public title: string,
    public description: string,
    public status: string) { }
}
export class Propertie {
  constructor(public _id: number,
    public title: string,
    public other_details: string) { }
}
export class Register {
  constructor(public _id: number,
    public role: string,
    public username: string,
    public email: string,
    public receiveNewsletter: any,
    public password: string,
    public approve_status: number,
    public other_details: string) { }
}
export class Cities {
  constructor(public _id: number,
    public cityname: string,
    public longitude: string,
    public latitude: string,
    public created_at: string,
    public status: string) { }
}
export class Aboutcompany{
  constructor(public _id: number,
    public icon_name: string,
    public title: string,
    public description: string,
    public created_at: string,
    public updated_at: string,
    public status: string) { }
}
export class Ourvision{
  constructor(public _id: number,
    public icon_name: string,
    public title: string,
    public description: string,
    public created_at: string,
    public updated_at: string,
    public status: string) { }
}
export class Ourmission{
  constructor(public _id: number,
    public title: string,
    public description: string,
    public images: string,
    public created_at: string,
    public updated_at: string,
    public status: string) { }
}
export class faq{
  constructor(public _id: number,
    public question: string,
    public answer: string,
    public created_at: string,
    public updated_at: string,
    public status: string) { }
}
export class ourclient{
  constructor(public _id: number,
    public title: string,
    public sub_title: string,
    public alt_name: string,
    public images: string,
    public created_at: string,
    public updated_at: string,
    public status: string) { }
}
export class Ourstory{
  constructor(public _id: number,
    public title: string,
    public description: string,
    public created_at: string,
    public updated_at: string,
    public status: string) { }
}
export class Ourservice{
  constructor(public _id: number,
    public icon_name: string,
    public description: string,
    public created_at: string,
    public updated_at: string,
    public status: string) { }
}
export class Zipcode {
  constructor(public _id: number,
    public cityid: string,
    public zipcode: number,
    public created_at: string,
    public status: string) { }
}
export class Neighborhood {
  constructor(public _id: number,
    public cityid: string,
    public zipcodeid: string,
    public neighborhood:string,
    public created_at: string,
    public status: string) { }
}
export class Feature {
  constructor(public _id: number,
    public features: string,
    public created_at: string,
    public status: string) { }
}
export class Country {
  constructor(public _id: number,
    public name: string,
    public currency: string,
    public currencySignature: string,
    public longitude: string,
    public latitude: string,
    public picture: string,
    public details: string,
    public displayOnFront: string,
    public created_at: string,
    public status: string) { }
}
export class Setsmtp {
  constructor(public _id: number,
    public edriver: string,
    public host: string,
    public port: string,
    public emailencryption: string,
    public email: string,
    public password: string,
    public created_at: string,
    public status: string) { }
}
export class Tag {
  constructor(public _id: number,
    public title: string,
    public slug: string,
    public created_at: string,
    public updated_at: string,
    public status: string) { }
}
export class pedate {
  constructor(public _id: number,
    public  rent: number,
    public  sale: number,
    public created_at: string,
    public updated_at: string) { }
}
export class Testimonial {
  constructor(public _id: number,
    public  name: string,
    public designation: string,
    public description: string,
    public created_at: string,
    public updated_at: string,
    public status: string) { }
}
export class Street {
  constructor(public _id: number,
    public streetname: string,
    public created_at: string,
    public updated_at: string,
    public status: string) { }
}
export class Propertytype {
  constructor(public _id: number,
    public title: string,
    public created_at: string,
    public updated_at: string,
    public status: string) { }
}
export class Propertystatus {
  constructor(public _id: number,
    public title: string,
    public created_at: string,
    public updated_at: string,
    public status: string) { }
}
export class facility {
  constructor(public _id: number,
    public title: string,
    public created_at: string,
    public updated_at: string,
    public status: string) { }
}
export class enquiry {
  constructor(public _id: number,
    public name: string,
    public email: string,
    public phone: number,
    public message: string,
    public created_at: string,
    public updated_at: string) { }
}
export class contact {
  constructor(public _id: number,
    public name: string,
    public email: string,
    public phone: number,
    public message: string,
    public created_at: string,) { }
}
export class feedback {
  constructor(public _id: number,
    public email: string,
    public message: string,
    public created_at: string,) { }
}
export class account {
  constructor(public _id: number,
    public company_id: string,
    public name: string,
    public lname: string,
    public uname: string,
    public mobile: string,
    public email: string,
    public password: string,
    public description: string,
    public role: string,
    public created_at: string,
    public updated_at: string,
    public status: string) { }
}
export class Area {
  constructor(public id: number,
    public value: number,
    public unit: string) { }
}

export class AdditionalFeature {
  constructor(public id: number,
    public name: string,
    public value: string) { }
}

export class Location {
  constructor(public propertyId: number,
    public lat: number,
    public lng: number) { }
}

export class Price {
  public sale: number;
  public rent: number;
}

export class Gallery {
  constructor(public id: number,
    public small: string,
    public medium: string,
    public big: string) { }
}

export class Plan {
  constructor(public id: number,
    public name: string,
    public desc: string,
    public area: Area,
    public rooms: number,
    public baths: number,
    public image: string) { }
}

export class Video {
  constructor(public id: number,
    public name: string,
    public link: string) { }
}

export class Pagination {
  constructor(public page: number,
    public perPage: number,
    public prePage: number,
    public nextPage: number,
    public total: number,
    public totalPages: number) { }
}

  
