import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Settings, AppSettings } from './app.settings';
import { Router, NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
declare var jQuery:any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public settings: Settings;
  public diclamer:boolean = false; 
  constructor(public _CookieService:CookieService, public appSettings:AppSettings, 
              public router:Router, 
              @Inject(PLATFORM_ID) private platformId: Object,
              public translate: TranslateService){
    this.settings = this.appSettings.settings;
    translate.addLangs(['en','de','fr','ru','tr']);
    translate.setDefaultLang('en'); 
    translate.use('en');
    jQuery('html body .pac-container').css({'opacity':'0'});
    const cookieValue = this._CookieService.get('diclamer_9estate');
    if(cookieValue){
      //alert(cookieValue);
    }else{
      this.diclamer = true;
    }
  }
  clickDeclaimer(){
    this._CookieService.set('diclamer_9estate', 'accepted', 30, '/');
    this.diclamer = false;
    //this._CookieService.get('diclamer_9estate');
   
  }
  ngAfterViewInit(){ 
    
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {   
        setTimeout(() => {
          if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0,0);
          }
        }); 
      }            
    });    
  }
  hideSearchSc(){
    jQuery('html body .pac-container').css({'opacity':'0'});
  }
}
