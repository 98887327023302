import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GlobalConstantsComponent } from '../../../common/global-constants/global-constants.component';
import { DataService } from 'src/app/_service/data-service.service';
@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
  public is_loggedIn = false;
  public is_loggedInUser = false;
  public myaccount:any;
  public myprofile_img:any;
  public user_name:any;
  public noti:any;
  public account_lname:any;
  public storage_data_id:any;
  public storage_data_id_user:any;
  public role: any;
  public full_name:any;
  public prof_img:any;
  constructor(public appService:AppService,public http: HttpClient, public dataService:DataService) { 
    this.storage_data_id = localStorage.getItem('login_agent_id');
    this.storage_data_id_user = localStorage.getItem('login_user_id');
    this.dataService.myData$.subscribe(data => {
      
      this.prof_img = GlobalConstantsComponent.siteUrl+'/assets/serverimg/'+data;
   
      //alert(data);
    });
    this.dataService.firstnameData$.subscribe(data => {
      this.user_name = data;
      });
  }

  ngOnInit() {
    this.dataService.myData$.subscribe(data => {
       
      this.myprofile_img = GlobalConstantsComponent.siteUrl+'/assets/serverimg/'+data;
   
      //alert(data);
    });
    this.dataService.firstnameData$.subscribe(data => {
      this.full_name = data;
    });
    this.getData();
    this.getDataagent();
    this.showNotificaation();
    if(localStorage.getItem('currentagentLgn') !== null){
      this.is_loggedIn = true;
    }else{
      this.is_loggedIn = false;
    }
    if(localStorage.getItem('currentuserLgn') !== null){
     
      this.is_loggedInUser = true;
    }else{
      this.is_loggedInUser = false;
    }
    
  }
  getData(){
		let params = new HttpParams();
		params = params.set('storage_data', this.storage_data_id_user);
		if(this.storage_data_id_user!=null){
			this.http.get(GlobalConstantsComponent.apiURL+'/useraccounts?' + params.toString())
			.subscribe((response: any) =>{
				this.user_name = response.accounts[0].name;

        this.myaccount = response.accounts[0];
      
        if(this.myaccount.profile_img==undefined){
  
          this.prof_img = 'https://i.imgur.com/BczLFIW.png';
        }else{
          if(this.myaccount.profile_img!='https://i.imgur.com/BczLFIW.png'){
            this.prof_img = GlobalConstantsComponent.siteUrl+'/assets/serverimg/'+this.myaccount.profile_img;
          }else{
            this.prof_img = 'https://i.imgur.com/BczLFIW.png';
          }
        }
			});
		}
	}
  getDataagent(){
		let params = new HttpParams();
		params = params.set('storage_data', this.storage_data_id);
		if(this.storage_data_id!=null){
					this.http.get(GlobalConstantsComponent.apiURL+'/agentaccounts?' + params.toString())
					.subscribe((response: any) =>{
						this.myaccount = response.accounts[0];
				  this.role = this.myaccount.role;
				  this.full_name =  response.accounts[0].name;
				  if(this.myaccount.profile_img==undefined){
					this.myprofile_img = 'https://i.imgur.com/BczLFIW.png';
				  }else{
					if(this.myaccount.profile_img!='https://i.imgur.com/BczLFIW.png'){
						this.myprofile_img =  GlobalConstantsComponent.siteUrl+'/assets/serverimg/'+this.myaccount.profile_img;
					  }else{
						this.myprofile_img = 'https://i.imgur.com/BczLFIW.png';
					  }
				  }
				  //this.myprofile_img=GlobalConstantsComponent.siteUrl+'/assets/serverimg/'+this.myaccount.profile_img;
					})
		}
	}
  logoutagent(event){
            localStorage.removeItem('currentagentLgn');  
            localStorage.removeItem('login_agent_id');
            localStorage.removeItem('login_agent_name');
            localStorage.removeItem('login_agent_password');
            localStorage.removeItem('utype');
            location.href = "/login";
   }
   logoutuser(event){
            localStorage.removeItem('currentuserLgn');  
            localStorage.removeItem('login_user_id');
            localStorage.removeItem('login_user_name');
            localStorage.removeItem('login_user_password');
            localStorage.removeItem('utype');
            location.href = "/login";
   }
   showNotificaation(){
    let params = new HttpParams();
		params = params.set('loginId', this.storage_data_id_user);
		this.http.get(GlobalConstantsComponent.apiURL+'/EnquiryReplyStatus?' + params.toString())
		.subscribe((response: any) =>{
     this.noti=response.total;
		
		})
   }


}
