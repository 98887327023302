import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AppService } from 'src/app/app.service';
import { AccountService } from '../../_service/account.service';
import {GlobalConstantsComponent } from '../../common/global-constants/global-constants.component';
@Component({
  selector: 'app-our-agents',
  templateUrl: './our-agents.component.html',
  styleUrls: ['./our-agents.component.scss']
})
export class OurAgentsComponent implements OnInit {
  public agents:any;
  public agent_img:any;
  public config: SwiperConfigInterface = { };
  public agentUrl:any;
  public siteUrl:any;
  is_loggedInUser: boolean;
  img: string;
  defaultimgpath: string;
  constructor(public appService:AppService, public _AccountService:AccountService,) { 
    this.agent_img=GlobalConstantsComponent.siteUrl+'/assets/serverimg/';
    this.defaultimgpath=GlobalConstantsComponent.siteUrl+'/assets/images/newimg/';
    this.img= this.defaultimgpath+"demopic_agent.jpg";
  }

  ngOnInit() {
    this.siteUrl = GlobalConstantsComponent.siteUrl;
    this._AccountService.getAccountsverifiedData().subscribe(data =>{
      this.agents=data['verified_accounts'];
      //this.agentUrl = GlobalConstantsComponent.siteUrl+'/'+this.agentData.slug_url;
    }); 

    // this.agents = this.appService.getAgents();
    if(localStorage.getItem('currentuserLgn') !== null){
     
      this.is_loggedInUser = true;
    }else{
      this.is_loggedInUser = false;
    }
  }

  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView: 4,
      spaceBetween: 16,       
      keyboard: true,
      navigation: true,
      pagination: true,
      grabCursor: true,        
      loop: false,
      preloadImages: false,
      lazy: true, 
      breakpoints: {
        320: {
          slidesPerView: 2
        },
        600: {
          slidesPerView: 2
        },
        960: {
          slidesPerView: 4
        },
        1280: {
          slidesPerView: 6
        }
      }
    }
  }


}
