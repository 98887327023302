// email-replace.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ouremailReplace'
})
export class OurEmailReplacePipe implements PipeTransform {
  transform(email: string, position: number = 5, replacementChar: string = '*'): string {
    const emailPrefix = email.substring(0, position);
    const emailSuffix = email.substring(position).replace(/./g, replacementChar);
    const onlyTenCharEmail = emailSuffix.substring(0, 14);
    return emailPrefix + onlyTenCharEmail;
  }
}
