import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlobalConstantsComponent} from '../common/global-constants/global-constants.component';

@Injectable({
  providedIn: 'root'
})
export class SubscribeService { 
  public save_subscription_url =  GlobalConstantsComponent.apiURL+"/savesubscription";
  constructor(private http:HttpClient) { }
  savesubscription(email:string){
    return this.http.post<{data:any}>(this.save_subscription_url,{email:email});
  }
}