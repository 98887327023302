import { Component, OnInit, Input } from '@angular/core';
import { MenuService } from '../menu.service';
import { Menu } from '../menu.model';

@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss'],
  providers: [ MenuService ]
})
export class HorizontalMenuComponent implements OnInit {
  @Input('menuParentId') menuParentId;
  public menuItems: Array<Menu>;
  public is_loggedIn = false;
  public is_loggedInUser = false;
  constructor(public menuService:MenuService) { }

  ngOnInit() {
    if(localStorage.getItem('currentagentLgn') !== null){
      this.is_loggedIn = true;
    }else{
      this.is_loggedIn = false;
    }
    if(localStorage.getItem('currentuserLgn') !== null){
     
      this.is_loggedInUser = true;
    }else{
      this.is_loggedInUser = false;
    }
    this.menuItems = this.menuService.getHorizontalMenuItems();
    this.menuItems = this.menuItems.filter(item => item.parentId == this.menuParentId); 
  }

}
