import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; 
import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LockScreenComponent } from './pages/lock-screen/lock-screen.component';
import { VerifyaccountComponent } from './pages/verifyaccount/verifyaccount.component';
import { AuthGuardAdmin } from './_service/auth-guard-admin.service'; 
import { AuthGuardAgent } from './_service/auth-guard-agent.service'; 
import { AuthGuardAdminAgent} from'./_service/auth-guard-admin-agent.service';
import{ ChatComponent } from'./chat/chat.component';
import { CustompriloadingService } from './_service/custompriloading.service';

export const routes: Routes = [
    { 
        path: '', 
        component: PagesComponent, children: [
            //{ path: 'singapore/:location', loadChildren: () => import('./pages/properties/properties.module').then(m => m.PropertiesModule) },
            { path: '', data :{preload:true}, loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
            { path: 'singapore/:location/:type/:type2', loadChildren: () => import('./pages/properties/properties.module').then(m => m.PropertiesModule) },
            { path: 'singapore/:location/:type', loadChildren: () => import('./pages/properties/properties.module').then(m => m.PropertiesModule) },
            { path: 'singapore/:location', loadChildren: () => import('./pages/properties/properties.module').then(m => m.PropertiesModule) },
            { path: 'verify-account/:code', component: VerifyaccountComponent },
            { path: 'properties/:id', loadChildren: () => import('./pages/property/property.module').then(m => m.PropertyModule) },
            { path: 'reset-pasword', loadChildren: () => import('./pages/resetpassword/resetpassword.module').then(m => m.ResetpasswordModule) },
            { path: 'about', data :{preload:true}, loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule) },
            { path: 'forgot-password/:code', loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
            { path: 'contact', data :{preload:true}, loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule) },
            { path: 'properties', data :{preload:true}, loadChildren: () => import('./pages/properties/properties.module').then(m => m.PropertiesModule) },
            { path: 'agents', loadChildren: () => import('./pages/agents/agents.module').then(m => m.AgentsModule) },
            { path: 'owner', loadChildren: () => import('./pages/agents/agents.module').then(m => m.AgentsModule) },
            { path: 'users', loadChildren: () => import('./pages/agents/agents.module').then(m => m.AgentsModule) },
            { path: 'compare', loadChildren: () => import('./pages/compare/compare.module').then(m => m.CompareModule) },
            { path: 'pricing', loadChildren: () => import('./pages/pricing/pricing.module').then(m => m.PricingModule) },
            { path: 'faq', loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule) },
            { path: 'login', data :{preload:true}, loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
            { path: 'quick-lucky-draw', loadChildren: () => import('./pages/quick-lucky-draw/quick-lucky-draw.module').then(m => m.QldModule) },
            { path: 'register', data :{preload:true}, loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
            { path: 'thankyou', loadChildren: () => import('./pages/thankyou/thankyou.module').then(m => m.ThankyouModule) },
            { path: 'lucky-draw-thankyou', loadChildren: () => import('./pages/lucky-draw-thankyou/lucky-draw-thankyou.module').then(m => m.LuckdrawThankyouModule) },
            { path: 'lucky-draw-terms-conditions', loadChildren: () => import('./pages/lucky-draw-termsconditions/lucky-draw-termsconditons.module').then(m => m.luckytermsConditionsModule) },
            { path: 'terms-conditions', loadChildren: () => import('./pages/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule) },
            { path: 'account', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule) },
            { path: 'user', loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule) }, 
            { path: 'submit-property', loadChildren: () => import('./pages/submit-property/submit-property.module').then(m => m.SubmitPropertyModule),canActivate: [AuthGuardAdminAgent] },
            { path: 'account/update-property/:property_slug', loadChildren: () => import('./pages/update-property/update-property.module').then(m => m.UpdateSubmitPropertyModule),canActivate: [AuthGuardAdminAgent] },
            { path: 'blog', data :{preload:true}, loadChildren: () => import('./pages/blog/blog.module').then(m => m.BlogModule) },
            { path: 'blog/:slug', loadChildren: () => import('./pages/post-details/post-details.module').then(m => m.PostDetailsModule) },
        ]
    },
    { path: 'admin',loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuardAdmin]},
    { path: 'adminlogin', loadChildren: () => import('./admin/adminlogin/adminlogin.module').then(m => m.AdminloginModule) },
    // { path: 'agent', loadChildren: () => import('./agent/agent.module').then(m => m.AgentModule), canActivate: [AuthGuardAgent]},
    // { path: 'agentlogin', loadChildren: () => import('./agent/agentlogin/agentlogin.module').then(m => m.AgentloginModule) },
    { path: 'landing', loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingModule) },
    { path: 'lock-screen', component: LockScreenComponent },
    { path: '404', component: NotFoundComponent }, 
    { path: 'chat', component:ChatComponent  }, 
    { path: '**', component: NotFoundComponent }, 
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
    preloadingStrategy: CustompriloadingService,
    initialNavigation: 'enabledBlocking'
})
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }