// chat.component.ts

import { Component, OnInit } from '@angular/core';
import { ChatService } from '../_service/chat.service';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  message: string = '';
  messages: any[] = [];

  constructor(private chatService: ChatService) {}

  ngOnInit(): void {
    this.chatService.receiveMessages().subscribe((message) => {
      this.messages.push(message);
    });
  }

  sendMessage() {
    this.chatService.sendMessage('User1', 'User2', this.message, 'user1-user2');
    this.message = '';
  }
}
