import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private myDataSubject = new BehaviorSubject<string>('');
  public myData$ = this.myDataSubject.asObservable();
  private firstNameSubject = new BehaviorSubject<string>('');
  public firstnameData$ = this.firstNameSubject.asObservable();
  public setMyData(value: string): void {
    this.myDataSubject.next(value);
  }
  public setMyFirstNameData(value: string): void {
    this.firstNameSubject.next(value);
  }
}