import { Component, OnInit } from '@angular/core';
import { GlobalConstantsComponent } from '../../common/global-constants/global-constants.component';
import { HttpClient, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss']
})
export class OurServicesComponent implements OnInit {
  public serviceData: any;
  imgUrl: any;

  constructor(public http: HttpClient,) { 
    this.imgUrl=GlobalConstantsComponent.siteUrl+'/assets/images/others/';
  }

  ngOnInit() {
    
  }
  // getData(){
	// 	this.http.get(GlobalConstantsComponent.apiURL+'/ourservice?'.toString())
	// 	.subscribe((response: any) =>{
  //     this.serviceData=response.Ourservices;
	// 	})
	// }

}
