import { Injectable } from '@angular/core';  
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';  
@Injectable({ providedIn: 'root' })  
export class AuthGuardAdminAgent implements CanActivate {  
    constructor(private _router: Router) { }  
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) { 
		if (localStorage.getItem('currentAdminLgn')) {  
            return true;  
        }  
        if (localStorage.getItem('currentagentLgn')) {  
            return true;  
        }  
        this._router.navigate(['/login']);  
        return false;  
    }  
  
} 