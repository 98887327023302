import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GlobalConstantsComponent } from '../../../common/global-constants/global-constants.component';
import{ NotificationTop}from'../../../_service/notificationtop.service';

@Component({
  selector: 'app-toolbar1',
  templateUrl: './toolbar1.component.html'
})
export class Toolbar1Component implements OnInit {
  @Output() onMenuIconClick: EventEmitter<any> = new EventEmitter<any>();
  public is_loggedIn = false;
  public is_loggedInUser = false;
  public mynotif:any;
  public storage_data_id_user:any;
  public fav:any;
  public log_ag = false;
  public user_crnt:any;
  constructor(public appService:AppService,public http: HttpClient, public _notificationTop:NotificationTop) { 
  this.storage_data_id_user = localStorage.getItem('login_user_id');
  this.mynotif = '';
  this._notificationTop.myallNotificationSubject$.subscribe(data => {
    this.mynotif = data;
    });
    this.user_crnt = localStorage.getItem('login_agent_id');
    if(this.user_crnt!==null){
      this.log_ag = true;
    }else{
      this.log_ag = false;
    }
  }

  ngOnInit() {
   
    this.showfav();
    if(localStorage.getItem('currentagentLgn') !== null){
      this.is_loggedIn = true;
    }else{
      this.is_loggedIn = false;
    }
    if(localStorage.getItem('currentuserLgn') !== null){
     
      this.is_loggedInUser = true;
    }else{
      this.is_loggedInUser = false;
    }
  }

  public sidenavToggle(){
    this.onMenuIconClick.emit();
  }

   showfav(){
    let params = new HttpParams();
		params = params.set('lgn_id', this.storage_data_id_user);
		this.http.get(GlobalConstantsComponent.apiURL+'/favorites?' + params.toString())
		.subscribe((response: any) =>{
      
     this.fav=response.total;

		})
   }
}