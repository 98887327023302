import { Component, OnInit } from '@angular/core';
import { GlobalConstantsComponent } from '../../common/global-constants/global-constants.component';
import { HttpClient, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.scss']
})
export class MissionComponent implements OnInit {
  public mission:any;
  public mission_image: string;
  constructor(public http: HttpClient,) { }

  ngOnInit() {
    this.getData();
  }
  getData(){
		this.http.get(GlobalConstantsComponent.apiURL+'/ourmission?'.toString())
		.subscribe((response: any) =>{
      this.mission=response.Ourmissions[0];
      this.mission_image=GlobalConstantsComponent.siteUrl+'/assets/serverimg/';
		})
	}
}
