import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlobalConstantsComponent } from '../common/global-constants/global-constants.component';

@Injectable({
  providedIn: 'root'
})
export class uploadAdservice {
  public getallhomemetas = GlobalConstantsComponent.apiURL + "/homemetas";
  public save_homemetas = GlobalConstantsComponent.apiURL + "/homemetas";
  public update_homemetas = GlobalConstantsComponent.apiURL + "/homemetas";
  public delete_homemetas = GlobalConstantsComponent.apiURL + "/homemetas";

  // route for page mata
  public getallpagemetabyid = GlobalConstantsComponent.apiURL + "/pagemetabyid";
  public getallpagemeta = GlobalConstantsComponent.apiURL + "/pagemeta";
  public save_pagemeta = GlobalConstantsComponent.apiURL + "/pagemeta";
  public update_pagemeta = GlobalConstantsComponent.apiURL + "/pagemeta";
  public delete_pagemeta = GlobalConstantsComponent.apiURL + "/pagemeta";
  public getmetaBySlug = GlobalConstantsComponent.apiURL+"/meta_by_slug";

  constructor(private http: HttpClient) { }
  gethomemetasData() {
    return this.http.get<any[]>(this.getallhomemetas);
  }

  // data  by Slug
  getdataslug(slug:string){
    return this.http.get<{data:any}>(this.getmetaBySlug+'/'+slug);
  }

  saveuploadtopbarad(metatitle: string, metadescription: string, focuskeyword: string, headtagscript: string) {
    return this.http.post<{ data: any }>(this.save_homemetas, { metatitle: metatitle, metadescription: metadescription, focuskeyword: focuskeyword, headtagscript: headtagscript });
  }
  updatehomemetas(id: string, metatitle: string, metadescription: string, focuskeyword: string, headtagscript: string) {
    return this.http.patch<{ updateddata: any }>(this.update_homemetas + '/' + id, { metatitle: metatitle, metadescription: metadescription, focuskeyword: focuskeyword, headtagscript: headtagscript });
  }
  deletehomemetas(id: string) {
    return this.http.delete<{ data: any }>(this.delete_homemetas + '/' + id);
  }

  // for pagemeta
  getpagedatabyid(id: string) {
    return this.http.get<{ data: any }>(this.getallpagemetabyid + '/' + id);
  }
  getpagemetaData() {
    return this.http.get<any[]>(this.getallpagemeta);
  }
  saveuploadpagemeta(pageurl: string,pagemeta: string, pagedescription: string, pagekeyword: string) {
    return this.http.post<{ data: any }>(this.save_pagemeta, { pageurl: pageurl, pagemeta: pagemeta, pagedescription: pagedescription, pagekeyword: pagekeyword });
  }
  updatepagemeta(id: string, pageurl: string,pagemeta: string, pagedescription: string, pagekeyword: string) {
    return this.http.patch<{ updateddata: any }>(this.update_pagemeta + '/' + id, { pageurl: pageurl, pagemeta: pagemeta, pagedescription: pagedescription, pagekeyword: pagekeyword });
  }
  deletepagemeta(id: string) {
    return this.http.delete<{ data: any }>(this.delete_pagemeta + '/' + id);
  }

}
