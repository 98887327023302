import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import{GlobalConstantsComponent} from '../../common/global-constants/global-constants.component';
@Injectable({
  providedIn: 'root'
})
export class PropertiesService {
  public getallproperty = GlobalConstantsComponent.apiURL+"/properties";

  public getFilterproperties = GlobalConstantsComponent.apiURL+"/getFilterproperties";


  public getagentproperty = GlobalConstantsComponent.apiURL+"/propertiesagent";
  public getagentpropertysale = GlobalConstantsComponent.apiURL+"/propertiesagentsale";
  public getagentpropertyrent = GlobalConstantsComponent.apiURL+"/propertiesagentrent";
  public getagentpropertyactive = GlobalConstantsComponent.apiURL+"/activepropertiesagent";
  public getagentenquiry = GlobalConstantsComponent.apiURL+"/totalagentenquiry";
  public save_Property =  GlobalConstantsComponent.apiURL+"/properties";
  public save_proprt =  GlobalConstantsComponent.apiURL+"/save_proprt";
  public update_Property = GlobalConstantsComponent.apiURL+"/properties";
  public delete_Property = GlobalConstantsComponent.apiURL+"/properties";
  public delete_Property_permanent = GlobalConstantsComponent.apiURL+"/propertiesDeletePermanent";
  public getPropertyByID = GlobalConstantsComponent.apiURL+"/properties";
  public featured_Property_url = GlobalConstantsComponent.apiURL+"/setfeaturedproperty";
  public unset_featured_Property_url = GlobalConstantsComponent.apiURL+"/unsetfeaturedproperty";
  public getactivepropertytype = GlobalConstantsComponent.apiURL+"/admin/getactivepropertytype";
  public getpropertystatus = GlobalConstantsComponent.apiURL+"/admin/getpropertystatus";
  public getallCity = GlobalConstantsComponent.apiURL+"/admin/getallCity";
  public getallStreet = GlobalConstantsComponent.apiURL+"/admin/getactivestreet";
  public getallfeatures = GlobalConstantsComponent.apiURL+"/admin/getactivefeature";
  public getallfacilityUrl = GlobalConstantsComponent.apiURL+"/admin/getactivefacilities";
  public getPropertiesByAgentId = GlobalConstantsComponent.apiURL+"/propertiesagent";
  public getPropertiesByAgentIdnew = GlobalConstantsComponent.apiURL+"/propertiesagentnew";
  public getfeaturedproperty = GlobalConstantsComponent.apiURL+"/properties/featured";
  public check_Url = GlobalConstantsComponent.apiURL+"/checkurl";
  public getPropertyBySlug = GlobalConstantsComponent.apiURL+"/property_by_slug";
  public save_proprt_new =  GlobalConstantsComponent.apiURL+"/save_proprt_new";
  public update_proprt_new =  GlobalConstantsComponent.apiURL+"/updateAgentProperty";
  public get_poperties_pagination = GlobalConstantsComponent.apiURL+"/properties_with_pag";
  public get_poperties_pagination_sort = GlobalConstantsComponent.apiURL+"/properties_with_pag_sort";
  public check_img_uri = GlobalConstantsComponent.apiURL+"/checkSensetiveImg";
  public check_sensitive_content_url = GlobalConstantsComponent.apiURL+"/checkSensetivePropTitle";
  public check_sensitive_desc_url = GlobalConstantsComponent.apiURL+"/checkSensetiveDesc";
  public filterHomeSearch = GlobalConstantsComponent.apiURL+"/filterHomeSearchUrl"; 
  public filterPrpertySearch = GlobalConstantsComponent.apiURL+"/filterPropertySearchUrl"; 
  public getActivePropertyFeaturesUrl = GlobalConstantsComponent.apiURL+"/activeFeaturesUrl"; 
  public get_poperties_pagination_sortAgent = GlobalConstantsComponent.apiURL+"/properties_with_pag_sort_Agent"; 
  public filterAgentListSearch = GlobalConstantsComponent.apiURL+"/filterAgentSearchUrl"; 
  constructor(private http:HttpClient) { }
  getProperty(){
		return this.http.get<any[]>(this.getallproperty); 
	}
  getHomeFilterProperty(lat:any, long:any){
		return this.http.get<any[]>(this.getFilterproperties+'/'+lat+'/'+long); 
	}
  getHomeSearchFilterProp(prop_status:any, prop_propertyType:any, location:any, bedrooms_from:any,bedrooms_to:any, area_from:any,area_to:any, price_from:any, price_to:any, prop_lat:any,prop_long:any,fetaures_all:any, page: number, limit: number, sorting:any){
    return this.http.post<{data:any}>(this.filterHomeSearch,{prop_status:prop_status, prop_propertyType:prop_propertyType,location:location,bedrooms_from:bedrooms_from, bedrooms_to:bedrooms_to, area_from:area_from, area_to:area_to, price_from :price_from, price_to:price_to, prop_lat:prop_lat, prop_long:prop_long, fetaures_all:fetaures_all, page: page, limit: limit, sorting:sorting});
		//return this.http.get<any[]>(this.getFilterproperties+'/'+lat+'/'+long); 
	}
  getAgentListSearchFilterProp(agentId:any, prop_status:any, prop_propertyType:any, location:any, bedrooms_from:any,bedrooms_to:any, area_from:any,area_to:any, price_from:any, price_to:any, prop_lat:any,prop_long:any,fetaures_all:any, page: number, limit: number, sorting:any){
    return this.http.post<{data:any}>(this.filterAgentListSearch,{agentId:agentId, prop_status:prop_status, prop_propertyType:prop_propertyType,location:location,bedrooms_from:bedrooms_from, bedrooms_to:bedrooms_to, area_from:area_from, area_to:area_to, price_from :price_from, price_to:price_to, prop_lat:prop_lat, prop_long:prop_long, fetaures_all:fetaures_all, page: page, limit: limit, sorting:sorting});
		//return this.http.get<any[]>(this.getFilterproperties+'/'+lat+'/'+long); 
	}
  getSearchFilterPropertyPage(prop_status:any, prop_propertyType:any, location:any, bedrooms_from:any,bedrooms_to:any, area_from:any,area_to:any, price_from:any, price_to:any, prop_lat:any,prop_long:any,fetaures_all:any, page: number, limit: number, sorting:any){
     return this.http.post<{data:any}>(this.filterPrpertySearch,{prop_status:prop_status, prop_propertyType:prop_propertyType,location:location,bedrooms_from:bedrooms_from, bedrooms_to:bedrooms_to, area_from:area_from, area_to:area_to, price_from :price_from, price_to:price_to, prop_lat:prop_lat, prop_long:prop_long, fetaures_all:fetaures_all, page: page, limit: limit, sorting:sorting});
		//return this.http.get<any[]>(this.getFilterproperties+'/'+lat+'/'+long); 
	}
  getfeatureProperty(){
		return this.http.get<any[]>(this.getfeaturedproperty); 
	}
  getagentProperty(){
		return this.http.get<any[]>(this.getagentproperty); 
	}
  getagentPropertysale(){
		return this.http.get<any[]>(this.getagentpropertysale); 
	}
  getagentPropertyactive(){
		return this.http.get<any[]>(this.getagentpropertyactive); 
	}
  gettotalagentenquiry(){
		return this.http.get<any[]>(this.getagentenquiry); 
	}
  getagentPropertyrent(){
		return this.http.get<any[]>(this.getagentpropertyrent); 
	}
  getPropertytypes(){
		return this.http.get<any[]>(this.getactivepropertytype);   
	}
  getPropertystatuses(){
		return this.http.get<any[]>(this.getpropertystatus); 
	}
  getActivePropertyFeatures(){
    return this.http.get<any[]>(this.getActivePropertyFeaturesUrl);
  }
  getallCities(){
		return this.http.get<any[]>(this.getallCity); 
	}
  getallStreets(){
		return this.http.get<any[]>(this.getallStreet); 
	}
  getallfeature(){
		return this.http.get<any[]>(this.getallfeatures);
	}
  getallfacilities(){
		return this.http.get<any[]>(this.getallfacilityUrl);
	}
  saveProperty(agentid:string,cityId:string,neighborhoodId:string,streetId:string,title:string, desc:string, priceDollar:string, priceEuro:string, propertyType:string, propertyStatus:string, location:string, city:string, zipcode:string, neighborhood:string, street:string, bedrooms:string, bathrooms:string, garages:string, area:string, yearBuilt:string, features:any, videoname:string, videolink:string, lat:any,lng:any, uploded_by_name:string){
    return this.http.post<{data:any}>(this.save_Property,{ agentId:agentid, cityId:cityId,neighborhoodId:neighborhoodId,streetId:streetId, title:title, desc:desc, priceDollar:Number(priceDollar), priceEuro :priceEuro, propertyType:propertyType, propertyStatus:propertyStatus, location:location, city:city, zipcode:zipcode, neighborhood:neighborhood, street:street, bedrooms:Number(bedrooms), bathrooms:bathrooms, garages:garages, area:Number(area), yearBuilt:yearBuilt, features:features, videoname:videoname, videolink:videolink,lat:lat,lng:lng, uploded_by_name:uploded_by_name});
  }
  checkSensetivePropTitle(titleP:string, descP:string){
    return this.http.post<{data:any}>(this.check_sensitive_content_url,{ title:titleP, desc:descP});
  }
  checkSensetivePropDesc(descP:string){
    return this.http.post<{data:any}>(this.check_sensitive_desc_url,{ desc:descP});
  }
  saveNewProperty(agentid:string,cityId:string,neighborhoodId:string,streetId:string,title:string, desc:string, priceDollar:string, priceEuro:string, propertyType:string, propertyStatus:string, location:string, city:string, zipcode:string, neighborhood:string, street:string, bedrooms:string, bathrooms:string,area:string, yearBuilt:string, features:any, videoname:string, videolink:string, gellary:string, lat:any,lng:any, uploded_by_name:string){
    return this.http.post<{data:any}>(this.save_proprt,{ agentId:agentid, cityId:cityId,neighborhoodId:neighborhoodId,streetId:streetId, title:title, desc:desc, priceDollar:Number(priceDollar), priceEuro :priceEuro, propertyType:propertyType, propertyStatus:propertyStatus, location:location, city:city, zipcode:zipcode, neighborhood:neighborhood, street:street, bedrooms:Number(bedrooms), bathrooms:bathrooms, area:Number(area), yearBuilt:yearBuilt, features:features, videoname:videoname, videolink:videolink,gellary:gellary,lat:lat,lng:lng, uploded_by_name:uploded_by_name});
  }
  updateProperty(id:string, title:string, desc:string, priceDollar:string, priceEuro:string, propertyType:string, propertyStatus:string, location:string, city:string, zipcode:string, neighborhood:string, street:string, bedrooms:string, bathrooms:string, garages:string, area:string, yearBuilt:string, features:any, name:string, link:string, uploded_by_name:string){
    return this.http.patch<{updateddata:any}>(this.update_Property+'/'+id,{title:title, desc:desc, priceDollar:Number(priceDollar), priceEuro :priceEuro, propertyType:propertyType, propertyStatus:propertyStatus, location:location, city:city, zipcode:zipcode, neighborhood:neighborhood, street:street, bedrooms:bedrooms, bathrooms:bathrooms, garages:garages, area:area, yearBuilt:yearBuilt, features:features, name:name, link:link, uploded_by_name:uploded_by_name});
  }
  updatestatus(id:string,status:any){
    return this.http.patch<{updateddata:any}>(this.update_Property+'/'+id,{status:status});
  }
  restoreProperty(id:string,trash:any){
    return this.http.patch<{updateddata:any}>(this.update_Property+'/'+id,{trash:trash});
  }
  getPropertyslug(slug:string){
    return this.http.get<{data:any}>(this.getPropertyBySlug+'/'+slug);
  }
  updatefeature(id:string,featured:Number){
    return this.http.patch<{updateddata:any}>(this.update_Property+'/'+id,{featured:featured});
  }
  setFeaturedProperty(property_id:string, agentId:string){
    return this.http.get<{data:any, message_type:any, message:any}>(this.featured_Property_url+'/'+property_id+'/'+agentId);
  }
  unsetFeaturedProperty(property_id:string, agentId:string){
    return this.http.get<{data:any}>(this.unset_featured_Property_url+'/'+property_id+'/'+agentId);
  }
  deleteProperty(id:string){
    return this.http.delete<{data:any}>(this.delete_Property+'/'+id);
  }
  deletePermanentProperty(id:string){
    return this.http.delete<{data:any}>(this.delete_Property_permanent+'/'+id);
  }
  getPropertyById(id:string){
    return this.http.get<{data:any}>(this.getPropertyByID+'/'+id);
  }
  getPropertyByagentId(id:string){
    return this.http.get<{data:any}>(this.getPropertiesByAgentId+'/'+id);
  }
  getPropertyByagentIdNew(id:string, sort:string, count:any){

    return this.http.post<{data:any}>(this.getPropertiesByAgentIdnew+'/'+id,{sorting:sort, count:count});

  }
  updateAgentPropertyWithSlug(property_id:any, agentid:string, plan_id:any,cityId:string,neighborhoodId:string,streetId:string,title:string, desc:string, priceDollar:string, priceEuro:string, propertyType:string, propertyStatus:string, location:string, city:string, zipcode:string, neighborhood:string, street:string, bedrooms:string, bathrooms:string,area:string, yearBuilt:string, features:any, features_icon:any, facilities:any, facilities_icon:any, videoname:string, videolink:string, gellary:string, lat:any,lng:any, uploded_by_name:string){
    return this.http.post<{data:any}>(this.update_proprt_new+'/'+property_id,{agentId:agentid, plan_id:plan_id, cityId:cityId,neighborhoodId:neighborhoodId,streetId:streetId, title:title, desc:desc, priceDollar:Number(priceDollar), priceEuro :priceEuro, propertyType:propertyType, propertyStatus:propertyStatus, location:location, city:city, zipcode:zipcode, neighborhood:neighborhood, street:street, bedrooms:Number(bedrooms), bathrooms:bathrooms, area:Number(area), yearBuilt:yearBuilt, features:features, features_icon:features_icon, facilities:facilities, facilities_icon:facilities_icon, videoname:videoname, videolink:videolink,gellary:gellary,lat:lat,lng:lng, uploded_by_name:uploded_by_name});
  }
  checkimgbyurl(img_url:any){
    return this.http.post<{data:any}>(this.check_img_uri,{ img_url:img_url});
  }
  saveNewPropertyWithSlug(agentid:string, plan_id:any,cityId:string,neighborhoodId:string,streetId:string,title:string, desc:string, priceDollar:string, priceEuro:string, propertyType:string, propertyStatus:string, location:string, city:string, zipcode:string, neighborhood:string, street:string, bedrooms:string, bathrooms:string,area:string, yearBuilt:string, features:any, features_icon:any, facilities:any, facilities_icon:any, videoname:string, videolink:string, gellary:string, lat:any,lng:any, uploded_by_name:string, slug:any){
    return this.http.post<{data:any}>(this.save_proprt_new,{ agentId:agentid, plan_id:plan_id, cityId:cityId,neighborhoodId:neighborhoodId,streetId:streetId, title:title, desc:desc, priceDollar:Number(priceDollar), priceEuro :priceEuro, propertyType:propertyType, propertyStatus:propertyStatus, location:location, city:city, zipcode:zipcode, neighborhood:neighborhood, street:street, bedrooms:Number(bedrooms), bathrooms:bathrooms, area:Number(area), yearBuilt:yearBuilt, features:features, features_icon:features_icon, facilities:facilities, facilities_icon:facilities_icon, videoname:videoname, videolink:videolink,gellary:gellary,lat:lat,lng:lng, uploded_by_name:uploded_by_name, slug:slug});
  }
  checkurl(slug:string){
    return this.http.get<{data:any}>(this.check_Url+'/'+slug);
  }
  
  getPginationProperties(page: number, limit: number){
		//return this.http.get<any[]>(this.getallproperty); 
    const params = { page: page.toString(), limit: limit.toString() };
    return this.http.get<any[]>(this.get_poperties_pagination, { params });
	}
  getPaginationPropertiesSorting(page: number, limit: number, sorting:any){
		//return this.http.get<any[]>(this.getallproperty); 
    const params = { page: page.toString(), limit: limit.toString(), sorting:sorting.toString() };
    return this.http.get<any[]>(this.get_poperties_pagination_sort, { params });
	}
  getPaginationPropertiesSortingAgent(agentId:any, page: number, limit: number, sorting:any){
		//return this.http.get<any[]>(this.getallproperty); 
    const params = { agentId:agentId, page: page.toString(), limit: limit.toString(), sorting:sorting.toString() };
    return this.http.get<any[]>(this.get_poperties_pagination_sortAgent, { params });
	}
}
