import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlobalConstantsComponent} from '../../../src/app/common/global-constants/global-constants.component';
@Injectable({
  providedIn: 'root'
})
export class AccountService {
  public getalladminAccounts = GlobalConstantsComponent.apiURL+"/adminaccounts";
  public getallAccounts = GlobalConstantsComponent.apiURL+"/accounts";
  public getallverifiedAccounts = GlobalConstantsComponent.apiURL+"/verified_accounts";
  public save_Accounts =  GlobalConstantsComponent.apiURL+"/accounts";
  public save_Accounts_new =  GlobalConstantsComponent.apiURL+"/accounts_new";
  public save_AgentAccounts =  GlobalConstantsComponent.apiURL+"/accounts";
  public update_Accounts = GlobalConstantsComponent.apiURL+"/accounts";
  public agentupdate_Accounts = GlobalConstantsComponent.apiURL+"/accounts";
  public update_Accountsverifiation = GlobalConstantsComponent.apiURL+"/accounts";
  public delete_Accounts = GlobalConstantsComponent.apiURL+"/accounts";
  public getagentAccounts = GlobalConstantsComponent.apiURL+"/agentaccounts";
  public getuserAccounts = GlobalConstantsComponent.apiURL+"/useraccounts";
  public update_agentAccounts = GlobalConstantsComponent.apiURL+"/agentupdateaccounts";
  public delete_agentAccounts = GlobalConstantsComponent.apiURL+"/accounts";
  public update_agentpassword = GlobalConstantsComponent.apiURL+"/agentupdatepassword";
  public agentaccount_verification = GlobalConstantsComponent.apiURL+"/agentemailverification";
  public agentByIdUrl =  GlobalConstantsComponent.apiURL+"/getagentbyid";
  public agentByUrl =  GlobalConstantsComponent.apiURL+"/getagentbySlug";
  public allAgents = GlobalConstantsComponent.apiURL+"/agentsAccounts";
  public check_Url = GlobalConstantsComponent.apiURL+"/agent/checkurl";
  public checkuserName = GlobalConstantsComponent.apiURL+"/agent/checkuserNameNew";
  //public checkEmail_url = GlobalConstantsComponent.apiURL+"/agent/checkEmail_url";
  public save_AgentAccounts_new = GlobalConstantsComponent.apiURL+"/accountsNewSave";
  public adminupdate_Accounts = GlobalConstantsComponent.apiURL+"/admin_update";
  constructor(private http:HttpClient) { }
  getAccountsData(){
		return this.http.get<any[]>(this.getallAccounts);
	}
  getAccountsverifiedData(){
		return this.http.get<any[]>(this.getallverifiedAccounts);
	}
  getagentsAll(){
		return this.http.get<any[]>(this.allAgents);
	}
  adminupdateAccount(id:string,name:string, email:string, mobile:string,profile_img:string,description:string){
    return this.http.patch<{updateddata:any}>(this.adminupdate_Accounts+'/'+id,{name:name, mobile:mobile, email:email,profile_img:profile_img, description:description});
  }
  getadminAccountsData(){
		return this.http.get<any[]>(this.getalladminAccounts);
	}
  getagentdata(){
		return this.http.get<any[]>(this.getagentAccounts);
	}
  getuserdata(){
		return this.http.get<any[]>(this.getuserAccounts);
	}
  getagentverification(){
		return this.http.get<any[]>(this.agentaccount_verification);
	}
  saveAccount(username:any, name:string, lname:string, uname:string, mobile:string, email:string, password:string,description:string, role:string, status:any, role_type:any){
    return this.http.post<{data:any}>(this.save_Accounts_new,{username:username, name:name, lname:lname, uname:uname,mobile:mobile, email:email, password:password, description:description, role:role, status:status, role_type:role_type});
  }
  saveagentregister(role:string,name:string,email:string,password:string,status:any,pathUrl:any,encoded_email:any){
    return this.http.post<{data:any}>(this.save_AgentAccounts,{name:name,email:email,role:role,password:password,status:'1',pathUrl:pathUrl,encoded_email:encoded_email});
  }
  saveagentregisterNew(role:string,name:string, username:string,email:string,mobile:string,password:string,status:any,pathUrl:any,encoded_email:any, slug:any, slug_url:any){
    return this.http.post<{data:any}>(this.save_AgentAccounts_new,{name:name, username:username,email:email,mobile:mobile,role:role,password:password,status:'1',pathUrl:pathUrl,encoded_email:encoded_email, slug:slug, slug_url:slug_url});
  }
  agentupdateAccount(id:string,name:string, email:string, mobile:string,profile_img:string,organization:string,facebook:string,twitter:string,linkedin:string,instagram:string,website:string){
    return this.http.patch<{updateddata:any}>(this.agentupdate_Accounts+'/'+id,{name:name, mobile:mobile, email:email,profile_img:profile_img, organization:organization, facebook:facebook,twitter:twitter,linkedin:linkedin,instagram:instagram,website:website});
  }
  updateAccount(id:string,name:string, lname:string, uname:string, mobile:string, email:string, password:string,description:string, role:string, status:any, role_type:any){
    return this.http.patch<{updateddata:any}>(this.update_Accounts+'/'+id,{ name:name, lname:lname, uname:uname,mobile:mobile, email:email, password:password, description:description, role:role, status:status, role_type:role_type});
  }
  updateAccountNew(id:string,name:string, lname:string, mobile:string, email:string, password:string,description:string, role:string, status:any, role_type:any){
    return this.http.patch<{updateddata:any}>(this.update_Accounts+'/'+id,{ name:name, lname:lname,mobile:mobile, email:email, password:password, description:description, role:role, status:status, role_type:role_type});
  }
  approveuser(id:string,status:any){
    return this.http.patch<{updateddata:any}>(this.update_agentAccounts+'/'+id,{status:status});
  }
  deleteAccount(id:string){
    return this.http.delete<{data:any}>(this.delete_Accounts+'/'+id);
  }
  Accountverification(id:string){
    return this.http.patch<{updateddata:any}>(this.update_Accountsverifiation+'/'+id,{status:status});
  }
  Update_agentpassword(id:string,password:string){
    return this.http.patch<{updateddata:any}>(this.update_agentpassword+'/'+id,{password:password});
  }
  getAgentById(id:any){
    return this.http.get<{data:any}>(this.agentByIdUrl+'/'+id);
  }
  getAgentByUrl(slug:any){
    return this.http.get<{data:any}>(this.agentByUrl+'/'+slug);
  }
  checkurl(slug:string){
    return this.http.get<{data:any}>(this.check_Url+'/'+slug);
  }
  checkUsernamefun(username:string){
    return this.http.get<{data:any}>(this.checkuserName+'/'+username);
  }
  /*checkEmailDuplicate(email:string){
    return this.http.get<{data:any}>(this.checkEmail_url+'/'+email);
  }*/
}
