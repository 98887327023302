import { Component, OnInit } from '@angular/core';
import { GlobalConstantsComponent } from 'src/app/common/global-constants/global-constants.component';
import { HttpClient, HttpParams } from '@angular/common/http';


@Component({
  selector: 'app-rightsidebar',
  templateUrl: './rightsidebar.component.html',
  styleUrls: ['./rightsidebar.component.scss']
})
export class RightsidebarComponent implements OnInit {
  imageUrl: string;
  loading: boolean = true;
  rightbarData: any;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.getImg();
  }
  getImg(){
    this.loading=true;
    this.http.get(GlobalConstantsComponent.apiURL+'/postrightbar')
    .subscribe((response: any) =>{
      this.rightbarData= response.Postrightbar[0]
      this.imageUrl=GlobalConstantsComponent.siteUrl+'/assets/serverimg/';
      this.loading=false;

    });
  }

}
